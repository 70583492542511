/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ShortsListSearchParams, {
  ShortsSearchType,
} from "./ShortsListSearchParams";
import {
  fetchShortSecondaryExcel,
  searchInventoryBundles,
} from "../../../../store/product.actions";
import ShipToCartHeader from "../../../../components/molecules/ShipToCartHeader";
import { clearSecondaryShortsResults } from "../../../../store/product.reducer";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import ShortsListResults from "./ShortsListResults";
import { ErrorResponse } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
};

const ShortsList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const { currentInventoryResults, status } = useAppSelector(
    (state) => state.product.secondaryshorts
  );
  const [error, setError] = useState<ErrorResponse | string>();
  const [isExcelSearch, setIsExcelSearch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const searchHandler = (params: ShortsSearchType) => {
    const customerId = currentCustomer?.id;
    setIsLoading(true);
    dispatch(searchInventoryBundles({ customerId, ...params })).unwrap()
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
  };

  const excelHandler = (params: ShortsSearchType) => {
    const customerId = currentCustomer?.id;
    setIsLoading(true);
    dispatch(fetchShortSecondaryExcel({ customerId, ...params }))
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  const clearResults = () => {
    dispatch(clearSecondaryShortsResults());
  };

  useEffect(() => {
    clearResults();
  }, [currentCustomer]);

  return (
    <div css={tw`p-2 w-full relative`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      {currentCustomer &&
        currentAuthorities?.includes("createOrderQuoteLoad") && (
          <ShipToCartHeader />
        )}
      <div
        css={tw`flex justify-between items-center bg-nucor-green w-full text-xs py-1 px-2 mt-2`}
      >
        <h2 css={[tw`text-white uppercase font-bold`]}>Floor Stock</h2>
      </div>
      <ShortsListSearchParams
        customer={currentCustomer}
        status={status}
        onSearch={(params, excel) => {
          setIsExcelSearch(excel);
          setHasSearched(true);
          clearResults();
          if (excel) {
            excelHandler(params);
          } else {
            searchHandler(params);
          }
        }}
      />

      {!hasSearched && (
        <p css={tw`mt-4 text-nucor-green text-xs font-semibold`}>
          * Select criteria and click search to view shorts inventory.
        </p>
      )}
      {isLoading && (
        <AnimatedLoadingSpinner css={tw`mt-4 ml-4`} />
      )}
      {hasSearched && !isExcelSearch && !isLoading && (
        <ShortsListResults results={currentInventoryResults} />
      )}

    </div>
  );
};

export default ShortsList;
