/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Modal from "../../../../components/molecules/Modal";
import { useAppDispatch } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import { updateQuickQuotePrices } from "../../../../store/document.actions";
import { ErrorResponse, QQPriceUpdateParams } from "../../../../types/types";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "../../../../components/molecules/Toast";
import { clearDocumentErrors } from "../../../../store/document.reducer";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
  selectedQuote: number;
  matchingUOM: boolean;
};

const ChangeAllPrices: React.FC<Props> = ({ selectedQuote, matchingUOM }) => {
   
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedUnitPrice, setSelectedUnitPrice] = React.useState("");
    const [error, setError] = useState<string | ErrorResponse>();
    const dispatch = useAppDispatch();

    const params: QQPriceUpdateParams = {
        quoteId: selectedQuote,
        unitPrice: selectedUnitPrice
      };

    const submitPriceChange = () =>     {
        if (isNaN(Number(selectedUnitPrice)) || Number(selectedUnitPrice) === 0) {
            setError("Invalid Price");
            return
        }
        dispatch(updateQuickQuotePrices(params))
        .unwrap()
        .then(() => {
            setModalOpen(false);
        })
        .catch((error: React.SetStateAction<string | ErrorResponse | undefined>) => {
          setError(error);
          setModalOpen(false);
        });
    }
    const clearErrors = () => {
        dispatch(clearDocumentErrors());
        setError("");
    }

  return (
    <>
        {error && (
            <Toast
            type="error"
            message={error}
            onConfirm={() => clearErrors()}
            />
        )}

        <TextButton
            onClick={() => {
                setSelectedUnitPrice("");
                setModalOpen(true);
            }}
            css={tw`ml-2 text-xs`}
        >
            Change All Prices
        </TextButton>

        {modalOpen && (
          <Modal onCancel={() => setModalOpen(false)} title="Change All Prices">
            <div css={tw`bg-[#ffffff] ml-1 mr-1`}>
                {!matchingUOM ? (
                    <>
                        <div css={tw`ml-1 flex items-center text-xs font-bold text-nucor-gray`}>
                            <FontAwesomeIcon
                                css={tw`text-orange-500 pr-2`}
                                size={"2x"}
                                icon={faExclamationTriangle}
                            />
                            <span>Cannot change all the prices because the price units do not match.</span>
                        </div>
                        <div css={tw`mt-2 flex pt-2 text-xs font-bold text-nucor-gray pb-2 justify-center`}>
                            <Button
                                css={[tw`text-xs text-white py-1 font-medium m-0 min-w-[12ch]`]}
                                type="button"
                                onClick={() => setModalOpen(false)}
                                >
                                Ok
                            </Button>  
                        </div>
                    </>
                ) : (
                    <>
                        <div css={tw`ml-1 flex items-center pt-1 text-xs`}>
                            <div>Change By:$<span css={tw`text-red-600`}>*</span></div>
                            <input
                                css={tw`w-16 mr-2 ml-2 bg-nucor-yellow`}
                                name="unitPrice"
                                type="number" 
                                autoFocus={true}
                                value={selectedUnitPrice} 
                                onChange={(e) => setSelectedUnitPrice(e.target.value)}
                                />
                            <span css={tw`mr-2`}>CWT</span>
                        </div>
                        <div css={[tw`flex gap-2 pt-2 pb-2`]}>
                        <Button
                            css={[tw`ml-2 text-xs text-white py-1 m-0 min-w-[12ch]`]}
                            type="button"
                            disabled={selectedUnitPrice === ""}
                            onClick={submitPriceChange}
                            >
                            Change
                            </Button>
                            <Button
                                css={[tw`text-xs text-white py-1 m-0 min-w-[12ch]`]}
                                type="button"
                                onClick={() => setModalOpen(false)}
                                >
                                Cancel
                            </Button>  
                        </div>
                    </>
                )}
            </div>     
          </Modal>)}
    </>
  );
};

export default ChangeAllPrices;