/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Interpolation, Theme } from "@emotion/react";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

type Props = {
  children?: ReactNode;
  css?: Interpolation<Theme>;
  hidePrevious?: boolean;
  hideWeekends?: boolean;
  onChange: (date?: Date) => void;
  value?: Date;
  name: string;
  minDate?: Date;
  maxDate?: Date;
  hasError?: boolean;
  highlight?: boolean;
  focus?: boolean;
};

const isWeekend = (date: Date) => date.getDay() === 0 || date.getDay() === 6;
const isBeforeToday = (date: Date) => {
  return date.toISOString() < new Date().toISOString();
};

function DatePicker({
  hidePrevious,
  hideWeekends,
  css,
  value,
  onChange,
  hasError,
  maxDate,
  minDate,
  highlight,
  focus,
  ...remainingProps
}: Props) {
  const dateRef = useRef<ReactDatePicker>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focus) {
      if (dateRef.current) {
        dateRef.current.setFocus();
        //dateRef.current.setOpen(false, true);
      }    
    }
  }, []);

  const isBeforeMax = (date: Date) => {
    if (!maxDate) return true;
    return date.toISOString() <= maxDate.toISOString();
  };

  const isAfterMin = (date: Date) => {
    if (!minDate) return true;
    return date.toISOString() >= minDate.toISOString();
  };

  return (
    <div css={[tw`inline-flex border border-gray-300 focus-within:shadow focus-within:border-blue-400`, css]}>
      <ReactDatePicker
        wrapperClassName="calendar-wrapper"
        autoComplete="off"
        filterDate={(date: Date) => {
          if (hidePrevious && isBeforeToday(date)) return false;
          if (hideWeekends && isWeekend(date)) return false;
          if (maxDate && !isBeforeMax(date)) return false;
          if (minDate && !isAfterMin(date)) return false;
          return true;
        }}
        ref={dateRef}
        css={[
          tw`px-1 py-1 text-xs cursor-pointer outline-none w-full border-none py-[2px]`,
          hasError && tw`border-red-600 border-r-[#ccc]`,
          highlight && tw`bg-nucor-yellow`,
        ]}
        selected={value}
        {...remainingProps}
        onChange={(date: Date) => onChange(date)}
        onSelect={() => buttonRef.current?.focus()}
      />
      <button
        onClick={() => {
          dateRef.current?.setOpen(true);
        }}
        type="button"
        css={tw`bg-[#e2e2e2] border border-none outline-none px-1 ml-[-1px]`}
        ref={buttonRef}
      >
        <FontAwesomeIcon className="text-[#1E40AF] mt-1" icon={faAngleDown} />
      </button>
    </div>
  );
}

export default DatePicker;
