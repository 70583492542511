/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";

import Button from "../../../../components/atoms/Button";
import Select from "../../../../components/atoms/Select";
import {
  ErrorResponse,
  CustomerSummaryItemResult,
  RequestStatus
} from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import { fetchDsms, fetchSalesReps } from "../../../../store/app.actions";
import CustomerSummaryResults from "./CustomerSummaryResults";
import {
  fetchCustomerSummaryList,
  fetchCustomerSummaryListExcel,
} from "../../../../store/customer.actions";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { selectMillsForSelectComponent } from "../../../../store/app.reducer";
import { fetchAllOpenOrdersExcel } from "../../../../store/shipping.actions";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

type SalesRepSelection = { key: string; contactName: string };
type DsmSelection = { key: string; contactName: string };

const Announcements: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [excelFlag, setExcelFlag] = useState(false);
  const [groupShipToFlag, setGroupShipToFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentContext = useAppSelector((state) => state.user.currentContext);
  const [error, setError] = React.useState<string | ErrorResponse>();
  const [salesReps, setSalesReps] = useState<SalesRepSelection[]>([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState("");
  const [selectedRepType, setSelectedRepType] = useState("SalesReps");
  const [dsmdata, setDsmData] = useState<DsmSelection[]>([]);
  const [selectedDsm, setSelectedDsm] = useState("");
  const [customerSummaryResults, setCustomerSummaryResults] = useState<
    CustomerSummaryItemResult | undefined | "excel"
  >();
  const isInternalUser = currentContext?.user?.authorities.includes("internal");
  const defaultMill = useAppSelector(selectDefaultMill);
  const [selectedMill, setSelectedMill] = useState(defaultMill);
  const millSelection = useAppSelector(selectMillsForSelectComponent);
  const [allExcelStatus, setAllExcelStatus] = useState<RequestStatus>("idle");

  if (!currentContext || !isInternalUser) return null;

  useEffect(() => {
    //if (initialLoad) {
    dispatch(fetchSalesReps())
      .unwrap()
      .then((reps) => setSalesReps(reps));
    dispatch(fetchDsms())
      .unwrap()
      .then((dsms) => setDsmData(dsms))
      .catch((error) => setError(error))
      ;
    //}
  }, []);

  const reps = [
    {
      value: 'All',
      label: 'All',
    },
    ...salesReps.map((rep) => ({
      value: rep.key,
      label: rep.contactName,
    })),
  ];

  const dsms = [
    {
      value: 'All',
      label: 'All',
    },
    ...dsmdata.map((dsm) => ({
      value: dsm.key,
      label: dsm.contactName,
    }))
  ];

  const repTypes = [
    { value: 'SalesReps', label: 'Sales Reps' },
    { value: 'DistrictSalesManagers', label: 'District Sales Managers' },
  ];

  const clickHandler = () => {
    setCustomerSummaryResults(undefined);
    if (excelFlag) {
      excelFlag && excelHandler();
    } else {
      searchCustomerSummaryHandler();
    }
  };

  const searchCustomerSummaryHandler = () => {
    if ((selectedRepType === 'SalesReps' && selectedSalesRep === "") ||
       (selectedRepType === 'DistrictSalesManagers' && selectedDsm === ""))
    return;
    setIsLoading(true);
    const selectedRep = selectedRepType === 'SalesReps' ? selectedSalesRep : selectedDsm;

    dispatch(fetchCustomerSummaryList({
      site: selectedMill === "ALL" ? "" : `NTP-${selectedMill}`,
      salesRep: selectedRep === "All" ? "" : selectedRep,
      groupByShipTo: groupShipToFlag,
    }))
      .unwrap()
      .then((result) => {
        setCustomerSummaryResults(result);
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  };

  const excelHandler = () => {
    setIsLoading(true);
    
    const selectedRep = selectedRepType === 'SalesReps' ? selectedSalesRep : selectedDsm;
    dispatch(fetchCustomerSummaryListExcel({ 
      site: selectedMill === "ALL" ? "" : `NTP-${selectedMill}`,
      salesRep: selectedRep === "All" ? "" : selectedRep,
      groupByShipTo: groupShipToFlag,
     }))
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  const downloadAllOpenOrdersExcel = () => {
    setAllExcelStatus("pending");
    dispatch(fetchAllOpenOrdersExcel())
      .unwrap()
      .then(() => setAllExcelStatus("success"))
      .catch((error) => {
        setError(error);
        setAllExcelStatus("error");
      });
  };

  return (
    <>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      <div
        css={tw`uppercase font-bold bg-nucor-green text-xs py-1 px-2 flex items-center`}
      >
        <span css={tw`text-white flex-1`}>Customer Summary</span>
        
        {allExcelStatus === "pending" && (
          <AnimatedLoadingSpinner colorStyle="light" message="" css={tw`mr-2`}/>
        )}
        <TextButton
          disabled={allExcelStatus === "pending"}
          onClick={downloadAllOpenOrdersExcel}
          type="button"
          css={[allExcelStatus !== "pending" && tw`text-white`]}
        >
          All Customers Open Orders
        </TextButton>
      </div>
      <div
        css={tw`flex p-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-1 text-xs items-center`}
      >
        <span>Mill:
              <Select
                name="mill"
                value={selectedMill}
                onChange={(mill: string) => setSelectedMill(mill)}
                minWidth={"20ch"}
                data={[
                  { label: "All", value: "ALL" },
                  ...millSelection.filter(mill => mill.sites.length <= 1)
                ]}
              /></span>

        <Select
          name="repType"
          value={selectedRepType}
          onChange={(reptype: string) => {
            setCustomerSummaryResults(undefined);
            setSelectedRepType(reptype)
            setSelectedSalesRep("")
            setSelectedDsm("")
          }}
          minWidth={"30ch"}
          data={repTypes}
        />
        {selectedRepType === 'SalesReps' && (
          <Select
            name="salesrep"
            value={selectedSalesRep}
            onChange={(salesrep: string) => {
              setCustomerSummaryResults(undefined);
              setSelectedSalesRep(salesrep)
            }}
            minWidth={"30ch"}
            data={reps}
          />
        )}
        {selectedRepType === 'DistrictSalesManagers' && (
          <Select
            name="dsms"
            value={selectedDsm}
            onChange={(dsm: string) => {
              setCustomerSummaryResults(undefined);
              setSelectedDsm(dsm)
            }}
            minWidth={"30ch"}
            data={dsms}
          />
        )}
        <span css={tw`ml-4`}>Group By Ship To:</span>
        <input
          type="checkbox"
          name="excel"
          checked={groupShipToFlag}
          onChange={(e) => {
            setGroupShipToFlag(e.target.checked);
            setCustomerSummaryResults(undefined);
          }}
        />
        <span css={tw`ml-4`}>Run as Excel:</span>
        <input
          type="checkbox"
          name="excel"
          checked={excelFlag}
          onChange={(e) => {
            setExcelFlag(e.target.checked);
          }}
        />
        <Button
          disabled={
            (selectedRepType === 'SalesReps' && selectedSalesRep === "") ||
            (selectedRepType === 'DistrictSalesManagers' && selectedDsm === "") ||
            isLoading
          }
          onClick={clickHandler}
          css={tw`ml-4`}
          type="button"
        >
          Search
        </Button>
      </div>
      {customerSummaryResults === "excel" && isLoading && (
        <AnimatedLoadingSpinner css={tw`mt-4 ml-4`} />
      )}
      {customerSummaryResults !== "excel" && (
        <CustomerSummaryResults
          isLoading={isLoading}
          groupShipToFlag={groupShipToFlag}
          results={customerSummaryResults}
        />
      )}
    </>
  );
};

export default Announcements;