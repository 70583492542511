/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import Modal from "./Modal";
import DatePicker from "../atoms/DatePicker";
import Button from "../atoms/Button";
import tw from "twin.macro";
import { dateToString } from "../../helpers/dateHelpers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentCustomerId } from "../../store/customer.reducer";
import Toast from "./Toast";
import { ErrorResponse, RequestStatus } from "../../types/types";
import { AsyncThunk } from "@reduxjs/toolkit";
import { FetchHistoricalPriceSheetsRequest } from "../../store/document.actions";
import AnimatedSpinner from "../atoms/AnimatedSpinner";
import ShipToSelect from "./ShipToSelect";

type Props = {
  children?: ReactNode;
  checkShipToPricing: boolean;
  onCancel: () => void;
  action: AsyncThunk<
    void,
    FetchHistoricalPriceSheetsRequest,
    { rejectValue: string | ErrorResponse }
  >;
};

const HistoricalPriceModal: React.FC<Props> = ({ checkShipToPricing, onCancel, action }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [toastInfo, setToastInfo] = React.useState("");
  const [status, setStatus] = React.useState<RequestStatus>("idle");
  const shipToId = useAppSelector((state) => state.customer.currentShipTo);
  const [selectedShipTo, setSelectedShipTo] = useState(shipToId);

  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );

  const viewHandler = () => {
    const date = dateToString(selectedDate);
    if (!customerId || !date) return;

    const params = (checkShipToPricing && currentCustomer?.shipToPricing) ? { id: customerId, shipToId: selectedShipTo, pointInTime: date } : { id: customerId, pointInTime: date };
    setStatus("pending");
    dispatch(action(params))
      .unwrap()
      .then(() => {
        onCancel();
      })
      .catch((err: unknown) => {
        if (typeof err === "string") {
          setToastInfo(err);
        } else {
          onCancel(); 
        }
      })
      .finally(() => setStatus("idle"));
  };
  
  return (
    <>
      {toastInfo && (
        <Toast
          type="error"
          message={toastInfo}
          onConfirm={() => setToastInfo("")}
        />
      )}
      <Modal title="Historical Price Sheet" onCancel={onCancel}>
        <div css={tw`mb-2 min-w-[185px]`}>
          {(checkShipToPricing && currentCustomer?.shipToPricing) && (
            <div className="flex gap-2 items-center mt-2 mr-4">
              <label css={tw`w-[45px]`}>Ship To</label>
              <ShipToSelect
                value={selectedShipTo}
                onChange={(value) => {
                  setSelectedShipTo(value);
                }}
                minWidth="50ch"
              />
            </div>)
          }

          <div css={tw`flex gap-2 mt-2`}>
            <label className="mt-1" css={(checkShipToPricing && currentCustomer?.shipToPricing) ? tw`w-[45px]` : tw`w-[35px]`}>Date</label>
            <DatePicker
              focus={true}
              value={selectedDate}
              name="historicalDate"
              onChange={(date) => setSelectedDate(date)}
            />
            {status === "pending" && <AnimatedSpinner css={tw`mt-1`} />}
          </div>

          <div className="mt-4 flex justify-end gap-2 items-center">
            <Button disabled={status !== "idle"} onClick={onCancel}>Cancel</Button>
            <Button disabled={!selectedDate || status !== "idle"} onClick={viewHandler}>Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HistoricalPriceModal;
