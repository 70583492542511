/** @jsxImportSource @emotion/react */
import React, { ReactNode, createContext, useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { fetchOrderDocumentsSetup } from "../../../../store/document.actions";
import OrdersContent from "./OrdersContent";
import OrdersLeft from "./OrdersLeft";
import { resetOrdersSearchCriteria } from "../../../../store/document.reducer";
import { ErrorResponse, PDFFile } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";

type Props = {
  children?: ReactNode;
};

export const PDFContext = createContext<{
  file: PDFFile | null;
  setFile: React.Dispatch<React.SetStateAction<PDFFile | null>>;
} | null>(null);

const OrdersList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [file, setFile] = React.useState<PDFFile | null>(null);
  const [error, setError] = React.useState<string | ErrorResponse>();

  useEffect(() => {
    if (!customerId) return;
    dispatch(fetchOrderDocumentsSetup(customerId))
    .unwrap()
    .catch((error) => setError(error));
    return () => {
      dispatch(resetOrdersSearchCriteria());
    };
  }, [customerId]);

  if (!customerId)
    return (
      <div css={[tw`flex p-4 w-full`]}>
        <p css={tw`text-nucor-green text-xs font-bold`}>Select a customer</p>
      </div>
    );
  return (
    <PDFContext.Provider value={{ file, setFile }}>
      <div css={[tw`flex p-2 w-full`]}>
        {error && (
          <Toast
            message={error}
            type="error"
            onConfirm={() => setError(undefined)}
          />
        )}
        <div css={[tw`w-[220px] px-1`]}>
          <OrdersLeft />
        </div>
        <div css={[tw`flex-grow px-1`]}>
          <OrdersContent />
        </div>
      </div>
    </PDFContext.Provider>
  );
};

export default OrdersList;
