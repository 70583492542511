/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { fractions } from "../../../../../../helpers/fractions";
import Button from "../../../../../../components/atoms/Button";
import Select from "../../../../../../components/atoms/Select";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { ErrorResponse, RequestStatus } from "../../../../../../types/types";
import { selectCurrentCustomerId } from "../../../../../../store/customer.reducer";
import {
  inchesToFormattedString,
  inchesToLength,
  lengthToInches,
} from "../../../../../../helpers/lengthHelpers";
import Loading from "../../../../../../components/atoms/Loading";
import InputWithValidation from "../../../../home/requestShipTo/InputWithValidation";
import ErrorDisplay from "../../../../../../components/molecules/ErrorDisplay";
import {
  fetchQuoteCartItemInstructions,
  updateQuoteCartItemInstructions,
} from "../../../../../../store/quoteCart.actions";
import Toast from "../../../../../../components/molecules/Toast";
import { stringToBase64 } from "../../../../../../helpers/stringToBase64";

type Props = {
  children?: ReactNode;
  salesOrderItemId: number;
  onClose: () => void;
} & ComponentProps<"div">;

type FormData = {
  fraction?: number;
  feet?: string;
  inches?: string;
  cutInHalf: boolean;
  quoteRequested: boolean;
  notes: string;
};

const SpecialInstructions: React.FC<Props> = ({
  onClose,
  salesOrderItemId,
  ...remainingProps
}) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { register, control, handleSubmit, setValue, watch } =
    useForm<FormData>();

  const [status, setStatus] = useState<RequestStatus | "updating">("idle");
  const [error, setError] = useState<string | ErrorResponse>();
  const [allowLengthChange, setAllowLengthChange] = useState(false);
  const [minLength, setMinLength] = useState<number>();
  const [maxLength, setMaxLength] = useState<number>();

  const cutInHalf = watch("cutInHalf");

  const submitHandler = handleSubmit((data) => {
    if (!customerId) return;
    const feet = data.feet ? +data.feet : 0;
    const inches = data.inches ? +data.inches : 0;
    const fraction = data.fraction ?? 0;
    const length = lengthToInches({ feet, inches, fraction });
    if (minLength && maxLength && length) {
      if (length < minLength || length > maxLength) {
        setError(
          `Cut length should be between ${inchesToFormattedString(
            minLength
          )} and ${inchesToFormattedString(maxLength)} in length`
        );
        return;
      }
    }
    setStatus("updating");
    dispatch(
      updateQuoteCartItemInstructions({
        customerId,
        salesOrderItemId,
        body: {
          cutInHalf: data.cutInHalf,
          cutLength: length && allowLengthChange ? length : undefined,
          quoteRequested: data.quoteRequested,
          encodedNotes: stringToBase64(data.notes),
        },
      })
    )
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      });
  });

  useEffect(() => {
    if (!customerId) return;
    setStatus("pending");
    dispatch(fetchQuoteCartItemInstructions({ customerId, salesOrderItemId }))
      .unwrap()
      .then((result) => {
        setAllowLengthChange(result.allowLengthChange);
        setMinLength(result.minBandSawLength);
        setMaxLength(result.maxBandSawLength);
        if (result.cutLength) {
          const length = inchesToLength(result.cutLength);
          setValue("feet", length?.feet ? length.feet.toString() : "");
          setValue("inches", length?.inches ? length.inches.toString() : "");
          setValue("fraction", length?.fraction);
        }
        setValue("cutInHalf", result.cutInHalf);
        setValue("quoteRequested", result.quoteRequested);
        setValue("notes", result.notes);
        setStatus("success");
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      });
  }, []);

  return createPortal(
    <div
      onClick={onClose}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100] text-xs`,
      ]}
      {...remainingProps}
    >
      {error && (
        <Toast
          onConfirm={() => setError(undefined)}
          type="error"
          message={error}
        />
      )}
      <div css={[tw`bg-[#cccccc66]`]} onClick={(e) => e.stopPropagation()}>
        <div css={[tw`bg-nucor-green p-[7px] max-w-[75ch]`]}>
          <h2 css={tw`font-bold text-white pb-1 text-xs`}>
            Special Instructions
          </h2>
          <form css={tw`bg-white p-1 pb-0 relative`}>
            {status === "pending" && <Loading />}
            {status === "updating" && <Loading>Updating</Loading>}
            {error && <ErrorDisplay error={error} />}
            {allowLengthChange && (
              <>
                <div css={tw`flex items-end gap-4 w-full justify-start ml-4`}>
                  <span css={[tw`font-bold text-nucor-gray mt-3`]}>
                    Band Saw Cut:
                  </span>
                  <fieldset css={tw``}>
                    <label css={tw`block`}>Feet</label>
                    <InputWithValidation
                      disabled={cutInHalf}
                      css={tw`max-w-[5ch] border border-[#ccc] px-1 py-px`}
                      {...register("feet")}
                      maxLength={2}
                      restrictToNumber
                    />
                  </fieldset>
                  <fieldset css={tw``}>
                    <label css={tw`block`}>Inches</label>
                    <InputWithValidation
                      disabled={cutInHalf}
                      css={tw`max-w-[5ch] border border-[#ccc] px-1 py-px`}
                      {...register("inches")}
                      maxLength={2}
                      restrictToNumber
                    />
                  </fieldset>
                  <fieldset css={tw``}>
                    <label css={tw`block`}>Fraction:</label>
                    <Select
                      disabled={cutInHalf}
                      name="fraction"
                      minWidth="10ch"
                      data={fractions}
                      control={control}
                    />
                  </fieldset>
                  <fieldset css={[tw`flex items-center gap-1`]}>
                    <input
                      id="cutInHalf"
                      {...register("cutInHalf")}
                      type="checkbox"
                      onClick={(e) => {
                        if (e.currentTarget.checked) {
                          setValue("feet", "");
                          setValue("inches", "");
                          setValue("fraction", 0);
                        }
                      }}
                    />
                    <label htmlFor="cutInHalf">Cut in Half</label>
                  </fieldset>
                </div>
                <hr css={tw`my-2 border border-t-nucor-gray opacity-50`} />
              </>
            )}
            <div css={[tw`grid grid-cols-2 gap-1 mb-2`]}>
              <fieldset css={[tw`flex items-center gap-1`]}>
                <input
                  id="quoteRequested"
                  {...register("quoteRequested")}
                  type="checkbox"
                />
                <label htmlFor="quoteRequested">Request Quote</label>
              </fieldset>
            </div>
            By checking request quote, a quote will be sent to our Inside Sales
            Team who will respond as soon as they can.
            <hr css={tw`my-2 border border-t-nucor-gray opacity-50`} />
            <fieldset>
              <label>Item Instructions:</label>
              <textarea autoFocus={true}
                css={tw`w-full border border-nucor-light-gray h-20 resize-none p-1`}
                {...register("notes")}
              />
            </fieldset>
            <div css={tw`text-right`}>
              <Button
                css={tw`text-xs font-normal mr-2 py-[3px] my-2 min-w-[10ch]`}
                type="button"
                onClick={submitHandler}
              >
                OK
              </Button>
              <Button
                css={tw`text-xs font-normal mr-2 py-[3px] my-2 min-w-[10ch]`}
                type="button"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default SpecialInstructions;
