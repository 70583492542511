import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationValues } from "../components/molecules/PaginationComponent";
import {
  OpenOrdersSetup,
  OrderDetailPagedResult,
  ReadyToReleaseSetup,
  ShippableItemPagedResult,
  DeliveryModeType,
  LoadCartHeader,
  RequestStatus,
  SearchCriteria,
  OrderTotals,
  ReadyToReleaseTotals,
  ErrorResponse,
} from "../types/types";
import { setCurrentCustomerDetails } from "./customer.actions";
import {
  addItemToLoad,
  autoLoadShipment,
  changeDeliveryMode,
  clearLoadCart,
  fetchLoadCart,
  fetchOpenOrders,
  fetchOpenOrdersSelectionData,
  fetchOpenOrdersTotals,
  fetchReleaseOrders,
  fetchReleaseOrdersSelectionData,
  fetchReleaseOrdersTotals,
  removeLoadCartItem,
  submitLoadCart,
  updateLoadCart,
  updateLoadCartItem,
} from "./shipping.actions";
import { RootState } from "./store";

type InitialState = {
  loads: {
    loadCart?: LoadCartHeader;
    status: RequestStatus;
    removeItemStatus: RequestStatus;
    updateLoadStatus: RequestStatus;
    submitLoadStatus: RequestStatus;
    error?: ErrorResponse | string;
  };
  openOrders: {
    selectionData?: OpenOrdersSetup;
    openOrdersTotals?: OrderTotals[];
    openOrdersResults?: OrderDetailPagedResult;
    searchCriteria: Omit<SearchCriteria, "sort" | "dir">;
    searchTabCriteria?: Omit<SearchCriteria, "sort" | "dir">;
    status: RequestStatus;
    requestType: "browse" | "search";
  };
  releaseOrder: {
    readyToRelease?: ShippableItemPagedResult;
    readyToReleaseTotals?: ReadyToReleaseTotals;
    selectionData?: ReadyToReleaseSetup;
    currentDeliveryMode: DeliveryModeType;
    searchCriteria: Omit<SearchCriteria, "sort" | "dir">;
    searchTabCriteria?: Omit<SearchCriteria, "sort" | "dir">;
    shipToIdToExpand?: string;
    status: RequestStatus;
    requestType: "browse" | "search";
  };
};

const initialState: InitialState = {
  loads: {
    loadCart: undefined,
    status: "idle",
    removeItemStatus: "idle",
    updateLoadStatus: "idle",
    submitLoadStatus: "idle",
  },
  openOrders: {
    selectionData: undefined,
    status: "idle",
    searchCriteria: {
      page: 1,
    },
    requestType: "browse",
  },
  releaseOrder: {
    selectionData: undefined,
    currentDeliveryMode: "Truck",
    status: "idle",
    searchCriteria: {
      page: 1,
    },
    requestType: "browse",
  },
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    setReleaseOrderShipToIdToExpand: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.releaseOrder.shipToIdToExpand = action.payload;
    },
    clearLoadErrors: (state) => {
      state.loads.error = undefined;
    },
    resetOpenOrderSearchCriteria: (state) => {
      state.openOrders.searchCriteria = {
        ...state.openOrders.searchCriteria,
        page: 1,
      };
      state.openOrders.openOrdersResults = undefined;
      state.openOrders.selectionData = undefined;
    },
    resetReleaseOrderSearchCriteria: (state) => {
      state.releaseOrder.searchCriteria = {
        ...state.releaseOrder.searchCriteria,
        page: 1,
      };
      state.releaseOrder.selectionData = undefined;
      state.releaseOrder.readyToReleaseTotals = undefined;
    },
    clearOrders: (state) => {
      state.openOrders.openOrdersResults = undefined;
    },
    updateReleaseOrderPagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.releaseOrder.searchCriteria = {
        ...state.releaseOrder.searchCriteria,
        ...action.payload,
      };
    },
    updateReleaseOrderSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.releaseOrder.searchCriteria = {
        ...state.releaseOrder.searchCriteria,
        ...action.payload,
      };
    },
    updateReleaseOrderSearchTabCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.releaseOrder.searchTabCriteria = action.payload;
    },
    updateOpenOrderPagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.openOrders.searchCriteria = {
        ...state.openOrders.searchCriteria,
        ...action.payload,
      };
    },
    updateOpenOrderSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.openOrders.searchCriteria = {
        ...state.openOrders.searchCriteria,
        ...action.payload,
      };
    },
    updateOpenOrderSearchTabCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.openOrders.searchTabCriteria = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentCustomerDetails.fulfilled, (state) => {
      //reset all the criteria and setup data when the customer is changed
      state.releaseOrder.searchCriteria = initialState.releaseOrder.searchCriteria;
      state.releaseOrder.searchTabCriteria = initialState.releaseOrder.searchTabCriteria;
      state.releaseOrder.selectionData = undefined;

      state.openOrders.searchCriteria = initialState.openOrders.searchCriteria;
      state.openOrders.searchTabCriteria = initialState.openOrders.searchTabCriteria;
      state.openOrders.selectionData = undefined;
    });
    builder.addCase(fetchOpenOrdersSelectionData.fulfilled, (state, action) => {
      state.openOrders.selectionData = action.payload;
    });
    builder.addCase(fetchOpenOrdersTotals.pending, (state, action) => {
      state.openOrders.requestType = action.meta.arg.requestType;
      state.openOrders.status = "pending";
    });
    builder.addCase(fetchOpenOrdersTotals.fulfilled, (state, action) => {
      state.openOrders.openOrdersTotals = action.payload;
      state.openOrders.status = "success";
    });
    builder.addCase(fetchOpenOrdersTotals.rejected, (state) => {
      state.openOrders.status = "error";
    });
    builder.addCase(fetchOpenOrders.fulfilled, (state, action) => {
      state.openOrders.openOrdersResults = action.payload;
    });
    builder.addCase(
      fetchReleaseOrdersSelectionData.fulfilled,
      (state, action) => {
        state.releaseOrder.selectionData = action.payload;
      }
    );
    builder.addCase(fetchReleaseOrders.fulfilled, (state, action) => {
      state.releaseOrder.readyToRelease = action.payload;
    });
    builder.addCase(fetchReleaseOrdersTotals.pending, (state, action) => {
      state.releaseOrder.requestType = action.meta.arg.requestType;
      state.releaseOrder.readyToReleaseTotals = undefined;
      state.releaseOrder.status = "pending";
    });
    builder.addCase(fetchReleaseOrdersTotals.fulfilled, (state, action) => {
      state.releaseOrder.readyToReleaseTotals = action.payload;
      state.releaseOrder.status = "success";
    });
    builder.addCase(fetchReleaseOrdersTotals.rejected, (state) => {
      state.releaseOrder.status = "error";
    });
    builder.addCase(fetchLoadCart.pending, (state) => {
      state.loads.status = "pending";
    });
    builder.addCase(fetchLoadCart.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.status = "success";
    });
    builder.addCase(fetchLoadCart.rejected, (state) => {
      state.loads.status = "error";
    });
    builder.addCase(addItemToLoad.pending, (state) => {
      state.loads.status = "pending";
    });
    builder.addCase(addItemToLoad.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.status = "success";
    });
    builder.addCase(addItemToLoad.rejected, (state) => {
      state.loads.status = "error";
    });
    builder.addCase(updateLoadCart.pending, (state) => {
      state.loads.error = undefined;
      state.loads.updateLoadStatus = "pending";
    });
    builder.addCase(updateLoadCart.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.updateLoadStatus = "success";
    });
    builder.addCase(updateLoadCart.rejected, (state, action) => {
      state.loads.error = action.payload;
      state.loads.updateLoadStatus = "error";
    });
    builder.addCase(submitLoadCart.pending, (state) => {
      state.loads.error = undefined;
      state.loads.submitLoadStatus = "pending";
    });
    builder.addCase(submitLoadCart.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.submitLoadStatus = "success";
    });
    builder.addCase(submitLoadCart.rejected, (state, action) => {
      state.loads.error = action.payload;
      state.loads.submitLoadStatus = "error";
    });
    builder.addCase(changeDeliveryMode.pending, (state) => {
      state.loads.status = "pending";
    });
    builder.addCase(changeDeliveryMode.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.releaseOrder.currentDeliveryMode = action.payload.deliveryMode;
      state.loads.status = "success";
    });
    builder.addCase(changeDeliveryMode.rejected, (state) => {
      state.loads.status = "error";
    });
    builder.addCase(autoLoadShipment.pending, (state) => {
      state.loads.status = "pending";
    });
    builder.addCase(autoLoadShipment.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.status = "success";
    });
    builder.addCase(autoLoadShipment.rejected, (state) => {
      state.loads.status = "error";
    });
    builder.addCase(clearLoadCart.pending, (state) => {
      state.loads.status = "pending";
    });
    builder.addCase(clearLoadCart.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.status = "success";
    });
    builder.addCase(clearLoadCart.rejected, (state) => {
      state.loads.status = "error";
    });
    builder.addCase(removeLoadCartItem.pending, (state) => {
      state.loads.error = undefined;
      state.loads.removeItemStatus = "pending";
    });
    builder.addCase(removeLoadCartItem.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.removeItemStatus = "success";
    });
    builder.addCase(removeLoadCartItem.rejected, (state) => {
      state.loads.removeItemStatus = "error";
    });
    builder.addCase(updateLoadCartItem.pending, (state) => {
      state.loads.status = "pending";
    });
    builder.addCase(updateLoadCartItem.fulfilled, (state, action) => {
      state.loads.loadCart = action.payload;
      state.loads.status = "success";
    });
    builder.addCase(updateLoadCartItem.rejected, (state) => {
      state.loads.status = "error";
    });
  },
});

export default shippingSlice.reducer;
export const {
  clearOrders,
  updateReleaseOrderPagination,
  updateOpenOrderPagination,
  updateOpenOrderSearchCriteria,
  resetOpenOrderSearchCriteria,
  updateReleaseOrderSearchCriteria,
  resetReleaseOrderSearchCriteria,
  updateOpenOrderSearchTabCriteria,
  updateReleaseOrderSearchTabCriteria,
  clearLoadErrors,
  setReleaseOrderShipToIdToExpand,
} = shippingSlice.actions;

export const selectOpenOrdersSelectionData = (state: RootState) =>
  state.shipping.openOrders.selectionData;

export const selectReleaseOrdersSelectionData = (state: RootState) =>
  state.shipping.releaseOrder.selectionData;
