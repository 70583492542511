/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Pagination, {
  PaginationValues,
} from "../../../../components/molecules/PaginationComponent";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { fetchOpenOrders } from "../../../../store/shipping.actions";
import {
  CustomerPart,
  OrderDetailPagedResult,
  OrderTotals,
  RequestStatus,
  SearchCriteria,
} from "../../../../types/types";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import {
  FetchOrderDocumentDetailPDFRequest,
  fetchOrderDocumentDetailPDF,
} from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import TextButton from "../../../../components/atoms/TextButton";
import { selectCurrentCustomerParts } from "../../../../store/parts.reducer";
import PartDescriptionModal from "../../../../components/molecules/PartDescriptionModal";
import { PDFContext } from "./OpenOrders";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import Loading from "../../../../components/atoms/Loading";

type Props = {
  children?: ReactNode;
  order: OrderTotals;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
};

const OpenOrdersDetails: React.FC<Props> = ({ order, currentSort }) => {
  const dispatch = useAppDispatch();
  const [fetchStatus, setFetchStatus] = useState<RequestStatus>("idle");
  const [results, setResults] = useState<OrderDetailPagedResult>();
  const [status, setStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPart, setSelectedPart] = useState<CustomerPart>();
  const [currentPagination, setCurrentPagination] = useState<PaginationValues>({
    page: 1,
  });
  const { searchCriteria, searchTabCriteria, requestType } = useAppSelector(
    (state) => state.shipping.openOrders
  );
  const currentCustomerParts = useAppSelector(selectCurrentCustomerParts);
  const ctx = useContext(PDFContext);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority("internal", currentAuthorities);

  const loading = status === "pending" || fetchStatus === "pending";

  const fetchResults = () => {
    setFetchStatus("pending");
    const criteria =
      requestType === "search"
        ? {
            ...searchTabCriteria,
            ...currentPagination,
            shipToCustomerId: order.shipToId,
            ...currentSort,
          }
        : {
            ...searchCriteria,
            ...currentPagination,
            shipToCustomerId: order.shipToId,
            ...currentSort,
            siteKey: undefined,
          };
    dispatch(
      fetchOpenOrders({
        customerId: order.customerId,
        criteria,
      })
    )
      .unwrap()
      .then((orders) => {
        setResults(orders);
        setFetchStatus("success");
      })
      .catch(() => {
        setFetchStatus("error");
      });
  };

  useEffect(() => {
    if (searchCriteria) {
      fetchResults();
    }
  }, [currentPagination, currentSort]);

  const viewOrderHandler = (params: FetchOrderDocumentDetailPDFRequest) => {
    setStatus("pending");
    setErrorMessage("");
    dispatch(fetchOrderDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setStatus("success");
      })
      .catch((error) => {
        setStatus("error");
        setErrorMessage(error);
      });
  };

  return (
    <div css={tw`relative`}>
      {selectedPart && (
        <PartDescriptionModal
          onCancel={() => setSelectedPart(undefined)}
          details={selectedPart}
        />
      )}
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <div
        css={tw`bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer py-1 px-2`}
      >
        <Pagination
          isLoading={fetchStatus === "pending"}
          items={results?.count ?? 0}
          onChange={(value) => setCurrentPagination(value)}
        />
      </div>

      <div css={tw`relative`}>
        {loading && <Loading />}
        <table className="ntp-portal-table" css={tw`w-full`}>
          <tbody>

          {results?.list && results?.list.length > 0 ? (
            results?.list.map((item, index) => (
              <tr key={index}>
                <td css={tw`w-[10%]`}>{item.customerPONumber}</td>
                <td css={tw`w-[12%]`}>
                  {item.hideDocumentLink ? (
                    `${item.salesOrderNumber}-${item.lineNumberUnprocessed ?? ""}`
                  ) : (
                    <TextButton
                      disabled={status === "pending"}
                      onClick={() =>
                        viewOrderHandler({
                          customerId: item.customerId,
                          id: item.salesOrderNumber,
                        })
                      }
                    >{`${item.salesOrderNumber}-${
                      item.lineNumberUnprocessed ?? ""
                    }`}</TextButton>
                  )}
                </td>
                <td css={tw`w-[6%]`}>{item.site}</td>
                <td css={tw`w-[8%] relative`}>
                  <span className="peer">{formatDateToShort(item.requestedShippingDate)}</span>
                  {item.nextRollingString && (
                    <span css={tw`absolute bg-white px-2 py-1 rounded border shadow-lg -right-24 -top-2 text-nucor-gray whitespace-nowrap animate-tooltipdelay hidden peer-hover:block`}>
                      {item.nextRollingString}
                    </span>
                  )}
                </td>
                <td css={tw`w-[6%]`}>
                  {formatDateToShort(item.confirmedShippingDate)}
                </td>
                <td css={tw`w-[24%]`}>
                  <TextButton
                    disabled={
                      !item.customerPartNumber ||
                      currentCustomerParts?.find(
                        (part) =>
                          part.customerPartNumber === item.customerPartNumber
                      ) === undefined
                    }
                    onClick={() => {
                      const part = currentCustomerParts?.find(
                        (part) =>
                          part.customerPartNumber === item.customerPartNumber
                      );
                      setSelectedPart(part);
                    }}
                  >
                    {item.customerPartNumber}
                  </TextButton>
                  {item.customerPartNumber && item.customerPartNumber && " - "}
                  {item.lineDescription}
                </td>
                <td css={tw`w-[4%] text-right`}>{item.totalLineOrderedPieces}</td>
                <td css={tw`w-[4%] text-right`}>{item.totalLinePiecesBalance}</td>

                <td className="group" css={[tw`w-[4%] text-right relative`, item.allocationAlert && tw`bg-[#f99]`,]}>
                  <span>
                    {numberFormatter(item.readyToLoadPieces)}
                  </span>
                  {item.allocationAlertMessage && (
                    <span css={tw`hidden group-hover:inline absolute bg-white px-2 py-1 border rounded border-red-600 text-red-600 whitespace-nowrap top-0 left-0 -translate-y-3/4`}>
                      {item.allocationAlertMessage}
                    </span>
                  )}
                </td>

                <td css={tw`w-[6%] text-right`}>{numberFormatter(item.totalLineWeight)}</td>
                <td css={tw`w-[6%] text-right`}>
                  {numberFormatter(item.totalLineWeightBalance)}
                </td>
                <td css={tw`w-[6%] text-right`}>
                  {numberFormatter(item.readyToLoadWeight)}
                </td>
                <td css={tw`w-[4%] text-center relative`} style={{backgroundColor: item.statusColor}}>
                  <span className="peer">{item.portalStatus}</span>
                  {isInternal &&
                    <span css={tw`absolute bg-white px-2 py-1 rounded border shadow-lg -left-11 -top-2 text-nucor-gray whitespace-nowrap animate-tooltipdelay hidden peer-hover:block`}>
                      {item.qualityTestingStatus}
                    </span>
                  }
                </td>
                
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={13}>
                {loading && <div>Loading...</div>}
                {!loading && <div>No Orders found for the selected criteria</div>}
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OpenOrdersDetails;
