/* eslint-disable @typescript-eslint/no-explicit-any */
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

type Props<T extends FieldValues> = {
  hasError?: boolean;
  data?: {
    value: string | number | boolean;
    label: any;
    selectedLabel?: any;
  }[];
  minWidth?: string;
  maxWidth?: string;
  onChange?: any;
  value?: string | number | boolean;
  highlight?: boolean;
  alignTo?: "left" | "right";
  disabled?: boolean;
  testId?: string;
  autoFocus?: boolean;
} & UseControllerProps<T, any>;

function Select<T extends FieldValues>(props: Props<T>) {
  if (!props.control) {
    return <SelectComponent {...props} />;
  } else {
    return <ControlledSelect {...props} />;
  }
}

function ControlledSelect<T extends FieldValues>(props: Props<T>) {
  const {
    field: { value, onChange },
  } = useController(props);

  return <SelectComponent value={value} onChange={onChange} {...props} />;
}

function alignOptionsTo(list: HTMLDivElement | null): "left" | "right" {
  if (list?.offsetWidth && list?.offsetLeft) {
    const rightmost = list.offsetWidth + list.offsetLeft;
    return rightmost < document.body.clientWidth * 0.75 ? "left" : "right";
  } else {
    return "left";
  }
}

function SelectComponent<T extends FieldValues>(props: Props<T>) {
  const [alignTo, setAlignTo] = useState<"left" | "right">(
    props.alignTo ?? "left"
  );
  const displayValue = props.data?.find((item) => item.value === props.value);
  const listboxRef = useRef<HTMLDivElement>(null);

  // Have to use className prop to style
  // component based on conflicts with twin-macro and headlessui

  useEffect(() => {
    if (!props.alignTo) {
      setAlignTo(alignOptionsTo(listboxRef.current));
    }
  }, [listboxRef.current]);

  const border = props.hasError ? "border-red-600" : "border-gray-300";

  return (
    <>
      <Listbox
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <div
          ref={listboxRef}
          className={`inline-block relative text-xs text-black border p-[1px] pl-1 focus-within:border-blue-400 focus-within:shadow ${border}`}
          style={{
            minWidth: props.minWidth,
            background: props.highlight ? "#ffff88" : "white",
            opacity: props.disabled ? 0.6 : 1,
            maxWidth: props.maxWidth,
          }}
        >
          <Listbox.Button
            autoFocus={props.autoFocus ? true : false}
            id={props.testId}
            className="flex w-full outline-none"
            data-testid={props.testId}
          >
            <span className="pr-4 text-left h-[18px] leading-[18px] overflow-hidden whitespace-nowrap">
              {displayValue?.selectedLabel || displayValue?.label} &nbsp;
            </span>
            <div className="absolute right-0 top-0 h-full w-5 bg-[#e2e2e2]">
              <FontAwesomeIcon
                className="text-[#1E40AF] mt-1"
                icon={faAngleDown}
              />
            </div>
          </Listbox.Button>
          <Listbox.Options
            className={`absolute bg-white top-[20px] ${
              alignTo === "left" ? "left-[-1px]" : "right-[-2px]"
            } outline-none focus-within:border-blue-400 border border-gray-300 z-20 max-h-60 whitespace-nowrap overflow-y-scroll`}
            style={{
              minWidth: props.minWidth,
            }}
          >
            {props.data?.map((item, index) => (
              <Listbox.Option
                className="w-full cursor-pointer"
                key={index}
                value={item.value}
                data-testid={item.value}
                id={`select-option-${item.value}`}
              >
                {({ active }) => (
                  <div
                    className="w-full"
                    style={{
                      background: active ? "#DFE8F6" : undefined,
                      border: active ? "1px dotted #A3BAE9" : undefined,
                      padding: active ? "0px 5px" : "1px 6px",
                    }}
                  >
                    {item.label}&nbsp;
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </>
  );
}

export default Select;
