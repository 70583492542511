/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import {
  selectCurrentCustomerId,
} from "../../../../store/customer.reducer";
import {
  selectReleaseOrdersSelectionData,
  updateReleaseOrderSearchCriteria,
} from "../../../../store/shipping.reducer";
import { fetchReleaseOrdersTotals } from "../../../../store/shipping.actions";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { PDFContext } from "./ReleaseOrders";
import { SearchCriteria } from "../../../../types/types";

type ReleaseOrderBrowseCriteria = {
  shipToCustomerId?: string;
  dateRangeType?: string;
  siteKey?: string;
  sites?: string[];
};

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const ctx = useContext(PDFContext);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const selectionData = useAppSelector(selectReleaseOrdersSelectionData);
  const { searchCriteria } = useAppSelector(
    (state) => state.shipping.releaseOrder
  );

  const getSites = (siteKey: string | undefined) : string[] | undefined => {
    if (!siteKey || !selectionData) {
      return undefined;
    }
    return siteKey === "ALL" ? undefined : selectionData.sites.find((item) => item.key === siteKey)?.sites;
  };

  const getBrowseCriteria = () : ReleaseOrderBrowseCriteria  => {
    const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
    const siteKey = searchCriteria.siteKey ?? defaultMill ?? "ALL"
    const dateRangeType = searchCriteria.dateRangeType ?? "all"
  
    const criteria = {shipToCustomerId, siteKey, dateRangeType};
  
    return criteria;
  };

  const getQueryCriteria = (browseCriteria: ReleaseOrderBrowseCriteria) : SearchCriteria => {
    const {shipToCustomerId, siteKey, dateRangeType} = browseCriteria;
    const criteria = {
      ...searchCriteria,
      shipToCustomerId: shipToCustomerId === "all" ? undefined : shipToCustomerId,
      dateRangeType,
      siteKey: siteKey,
      sites: getSites(siteKey)
    };

    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {shipToCustomerId, siteKey, dateRangeType} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const shipTos =
  selectionData?.shipTos.map((shipTo) => {
    return {
      value: shipTo.id,
      label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
    };
  }) ?? [];

  const search = (criteria: SearchCriteria) => {
    if (!customerId) return;

    dispatch(updateReleaseOrderSearchCriteria({ ...criteria, page: 1}));
    dispatch(fetchReleaseOrdersTotals({customerId, criteria: { ...criteria, siteKey: undefined }, requestType: "browse"}))
    .unwrap()
    .catch((error) => ctx?.setError(error));
  };

  useEffect(() => {
    if (selectionData) {
      const criteria = getQueryCriteria(getBrowseCriteria());
      search(criteria);
    }
  }, [selectionData]);
  
  const onSelectionsChanged = (criteria: ReleaseOrderBrowseCriteria) => {
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    search(criteria);
  };

  return (
    <>
      <TabGroupSubGroup title="By Ship To">
        <Select
          minWidth="90%"
          maxWidth="100%"
          name="byShipTo"
          data={[{ value: "all", label: "All" }, ...shipTos]}
          onChange={(value: string) => {
            onSelectionsChanged({
              ...criteria,
              shipToCustomerId: value === "all" ? undefined : value,
            });
          }}
          value={shipToCustomerId}
        />
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={siteKey === "ALL"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
              sites: undefined,
            });
          }}
        >
          All
        </ButtonSelect>
        {selectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={siteKey === item.key}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                  sites: getSites(item.key),
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Due Date">
        {selectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  dateRangeType: date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default ReleaseOrdersBrowse;
